


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body{
  background-color: black;
  /* background-image: url(../img/background.jpg); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-width: 600px;
  width: 100%;
  height: 100%;
}
.banner{
  /* padding: 1.4vw; */
  background-color: gray;
  /* display: grid;
  grid-template-columns: 25% auto;
  grid-template-rows: 15% auto;
  grid-gap: 10px; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 
@media only screen and (min-width: 768px) {
  body {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/images/kenjilam_baltimore.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
} */
.paddinglist{
  margin-left: -25px;
}


/* background image */
/* .backgroundImage{
  src: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/images/kenjilam_baltimore.png");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */

/* Projects page */

.projectButton {
  opacity: 1;
  transition: .5s ease;
  backface-visibility: hidden;
}
.projectTitle{
  transition: .5s ease;
  opacity: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(52,58,64,0.9);
  font-weight: bolder;
}
.projectButton:hover .projectTitle{
  opacity: 1;
}

.rotateLeft{
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

.full-page{
  min-height: 98vh;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f118;
    
}
::-webkit-scrollbar-thumb {
    background-color: #f8f8f8d4; /* Set the color of the scrollbar thumb */
    border-radius: 12px; /* Set the border radius of the scrollbar thumb */
  }
  ::-webkit-scrollbar {
    width: 6px; /* Set the width of the scrollbar */
    height: 6px;
  }

  .skillRow{
    max-width: 100%;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-bottom: 1rem;
  }
  
  .page{
    width: 100vw; 
    height:fit-content; 
    /* position:relative;  */
    /* right:0; */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow-x: hidden;
  }

  .phonePage{
    margin-top: 10vh;
  }

  .backgroundLatop{     
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../public/images/kenjilam_baltimore.jpg);
    width: 100vw;
    height: 100vh;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: scroll !important;
    overflow-x: hidden;
  }
  .backgroundPhone{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0)), url(../public/images/kenjilam_palace.jpeg);
        width: 100vw;
        max-height: calc(100vh - 107.6px);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: fixed;
        top: 57.6px;
        overflow-y: scroll;
        overflow-x: hidden;
  }   

  .pageSection{
    width: 70vw;
    min-height: 70vh;
    margin-bottom: 30vh;
  }

 .phonePageSection{
    background-color: rgba(0,0,0, 0.8);
    width: 100vw;
    min-height: 70vh;
    height: fit-content;
    margin-bottom: 30vh;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-top: 3vh;
    padding-bottom: 3vh;
  }
  .cardIconText{
    font-size: small;
  }

  .contactSection{
    border: none; 
    /* max-width:100%; 
    max-height:100vh; */
    /* width:100%;
    height: 100%; */
    width:100%;
    height: 100vh;
  }
  
  .modalFrame{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* responsive class for multi devices */

  .phone{
    display: none;
  }
  @media (max-width: 760px) {
    .laptop{
      display: none;
    }
    .phone{
      display: inline;
    }
  }

  .laptopMenu{
    position: fixed;
    width: fit-content;
    height: fit-content;
    top:0;
    left:0;
    z-index: 5
  }
  .menuText{
    writing-mode: vertical-rl;
    /* text-orientation: mixed; */
    transform: rotate(180deg);
    white-space: nowrap;
  }

  .socialLinks{
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 1vh;
    left: 0;
  }
  .socialButton{
    width: 50px;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
  }

  @media (max-height: 702px) {
    .socialLinks{
      flex-direction: unset;
      
    }
  }

  @media (max-height: 470px) {
    .socialLinks{
      flex-direction: unset;
      width: fit-content;
    }
    .menuText{
      writing-mode: unset ;
      transform: rotate(0deg);
    }
  }

  @media (max-width: 865px){
    .socialButton{
      width: 5.9vw;
    }
    .socialLinks{
      width: fit-content;
    }
  } 

  @media (max-height: 270px) {
    .socialLinks{
      flex-direction: column;
      width: fit-content;
      left: 105px;
      top:0
    }
    
  }